// fs-studyprogram-frontpage fs-studyprogram-document fs-emne

// Study context banner
body {
    .vrtx-context-message-box.grid-container {
        background-color: var(--color-dark-blue);
        margin-bottom: 0px;
        padding-bottom: var(--space-s);
        .row {
            background-color: var(--color-message);
            max-width: 100%;
            margin: 0;
            padding: 0;
            .content-wrapper {
                max-width: var(--width-total-max);
                margin: 0 auto;
                padding: var(--space-s) 50px;
                .message-text {
                    background: var(--icon-message) no-repeat left center;
                    padding-left: 45px;
                }
            }
        }
    }
    .vrtx-context-box.vrtx-context-box-linked {
        display: none;
    } 
}

body {
    &#vrtx-fs-studieprogram-frontpage, &#vrtx-fs-studieprogram-document, &#vrtx-fs-emne {
        .vrtx-introduction {
            margin-bottom: 0;
        } 
        .vrtx-context-box {
            padding-bottom: 0;
        }
        
        #vrtx-fs-studieprogram-content, #vrtx-fs-studieprogram-additional-content, #vrtx-fs-emne-content {
            max-width: var(--width-total);
            margin-left: auto;
            margin-right: auto;
            margin-top: var(--space-m);
            .vrtx-article-body, #vrtx-fs-studieprogram-plans, .vrtx-studieprogram-additional-content {
                max-width: var(--width-text-max);
                border: 0;
                padding-bottom: var(--space-m);
            }
            #vrtx-fs-studieprogram-plans, .vrtx-studieprogram-additional-content {
                h2 {
                    margin-top: var(--space-m);
                }
            }
            #vrtx-fs-studieprogram-plans {
              ul {
                padding-left: 0;
              }
              li {
                border: 1px solid black;
                width: fit-content;
                border-radius: var(--radius-l);
                padding: 10px 50px 10px 24px;
                position: relative;
                &::marker {
                  color: transparent;
                }
                &::after {
                  content: "";
                  background: var(--arrow-medium) no-repeat;
                  background-size: contain;
                  width: 17px;
                  height: 17px;
                  position: absolute;
                  top: 15px;
                  right: 15px;
                  transition: 0.2s right ease-in-out;
                }
                &:hover::after, &:focus::after {
                  right: 10px;
                }
                a {
                  text-decoration: none;
                }
              }
            }
            #vrtx-fs-studieprogram-contact {
                background-color: var(--color-secondary-green-light);
                padding: var(--space-l) 50px var(--space);
                margin-top: var(--space-m);
            }
        }
    }
}
#vrtx-fs-emne {
  .connected-links {
    padding-bottom: var(--space-m);
    .button {
      border-color: white;
      &::after {
          background-image: var(--arrow-medium-white);
      }
    }
  }
}

// Studyprogram listing
#vrtx-fs-studieprogram-listing {
    #vrtx-listing-filters {
        margin-top: var(--space-s);
    }
}

// Studyprogram-finder
body#vrtx-fs-studieprogram-finder {
  #study-how-to-apply-link {
    display: block;
    max-width: var(--width-total-max);
    margin: 0px auto;
    padding: 0 50px 20px 50px;
    &::after {
      background-image: var(--arrow-read-more-white);
    }
  }
    font-size: 1.5rem;
    #study-program-finder {
        gap: var(--space-m);
        .studies-list {
            h3 {
                margin-bottom: var(--space-xs);
            }
            li:has(.no-applicants) .facet:not(.no-applicants) {
              display: inline-block;
              &.topic {
                display: none;
              }
            }
        }
    }
    #vrtx-main-content {
        background-color: var(--color-dark-blue-light);
        padding: var(--space-m) 0;
        margin-top: -50px;
        .studies-filter {
            font-size: 1.5rem;
            h2 {
                color: black;
            }
            input, button, select {
                padding: 10px 20px;
                border-color: var(--color-border);
            }
            .button-filter {
                label {
                    border-color: var(--color-border);
                    border-radius: var(--radius-l);
                    padding: 5px 10px;
                    &.selected {
                        background-color: var(--color-dark-blue);
                    }
                }
                li {
                    margin: 0px 4px 0px 0;
                    padding-top: var(--space-xs);
                }
            }
        }
        .studies-feedback {
            background-color: transparent;
            padding: 0;
            margin-bottom: 10px;
            .studies-message h2 {
                font-size: 2.2rem;
            }
        }
        .studies-list h3 {
            font-size: 2.6rem;
        }
    }
}

#vrtx-fs-studieprogram-document {
    #toc {
        ul li ul {
          display: none;
        }
    }
    #vrtx-main-content {
      .vrtx-fs-study-model {
        .course-list {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: calc(var(--space-s) + var(--space-xs));
            margin-top: var(--space-s);
            li {
                position: relative;
                background-color: var(--color-light-blue-light);
                margin-bottom: var(--space-xs);
                padding: 0;
                border-radius: var(--radius-m);
                &:hover, &:focus {
                  background-color: var(--color-light-blue);
                }
            }
            li:not(.mandatory){
                .course-code{
                    &:after{
                        content: "\00a0 / Valgbart emne";
                        display: inline-block;
                        color: var(--color-black);
                    }
                }
            }
            .course-description{
              display: flex;
              flex-direction: column;
              row-gap: var(--space-xs);
                .course-code {
                    font-weight: 700;
                    color: var(--color-black);
                }
                .course-info {
                    display: block;
                    color: var(--color-link);
                }
                .course-terms {
                    order: 3;
                    color: var(--color-black);
                    &:before{
                        content: "\00a0 /\00a0";
                    }
                }
            }
            .course-study-points{
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 4.5em;
                width: 4.5em;
                height: 4.5em;
                color: #fff;
                background: var(--color-dark-blue);
                border-radius: var(--radius-l);
                margin-left: auto;
                span:first-child{
                    font-weight: 700;
                }
                span:first-child:after {
                    content: "\00a0";
                }
            }
            .course-link, .course-not-linked {
              display: flex;
              align-items: center;
              column-gap: var(--space-xs-s);
              padding: var(--space-xs-s);
              .course-description {
                &:hover {
                  background-color: var(--color-neutral--light);
                  .course-info {
                    text-decoration: underline;
                  }
                }
              }
            }
            .course-not-linked {
              .course-not-linked-info {
                color: var(--color-black);
                display: block;
              }
            }
        }
        form[id*="direction-form-"] {
          max-width: fit-content;
          label {
            background-color: var(--color-light-blue-light);
            width: 100%;
            border-radius: var(--radius-s);
            color: var(--color-black);
            &:hover, &:focus {
              background-color: var(--color-light-blue);
            }
            &::before {
              box-shadow: 0 0 0 2px var(--color-dark-blue);
            }
          }
          input:focus + label {
            outline: 2px solid var(--color-dark-blue);
            outline-offset: 2px;
          }
          input:checked + label {
            background-color: var(--color-dark-blue);
            color: white;
            &::before {
              background-color: var(--color-dark-blue);
            }
          }
        }
      }
    }
  }


// Special design program frontpage
// /studier/politiutdanning/index.html

#vrtx-fs-studieprogram-frontpage {

  // Fullwidth background, content grid
  // This drag out from content mechanism for a colored bg row, should maybe be made into
  // a general mixin that uses grid width variables (mixin to drag content fullwidth background)
  .img-special-left,
  .img-special-right,
  .three-column-grid,
  .two-column-grid {
    &:first-child {
      margin-top: -40px;
    }

    width: 100vw;
    background-color: var(--color-secondary-green-light);
  
    margin-left: calc(520px - 50vw);
    @media (max-width: 1155px) {
      margin-left: -50px;
    }
    @media (@screen-below-medium) {
      margin-left: -15px;
    }

    > div {
      margin-right: auto;
      margin-left: auto;
      max-width: var(--width-total-max);
      padding: var(--space-l) 50px; // 50px here is grid specific
  
      @media (@screen-below-medium) {
        padding: var(--space-m) 15px; // 15px here is grid specific
      }
    }
  }

  .img-special-left,
  .img-special-right {
    > div {
      @media (@screen-below-medium) {
        padding: 0 0 var(--space-m) 0; // 15px here is grid specific
      }
    }
  }

  // Columns grid in content
  // TODO improvement: X-candidate? this could mmaybe be useful generally on PHS or X?
  .three-column-grid,
  .two-column-grid {
    > div {
      display: grid;
      h2 {
        &:after {
          content: "";
          display: block;
          margin: var(--space-s) 0;
          background-color: var(--color-black);
          width: 40px; // custom design
          height: 4px;
        }
      }    
      > * > *:first-child {
        margin-top: 0;
      }
      > * > *:last-child {
        margin-bottom: 0;
      }
    }
  }
  .three-column-grid > div {
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--space-m);
  }
  .two-column-grid > div {
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--space-l);
  }
  @media @responsiveBreakpoint {
    .three-column-grid > div,
    .two-column-grid > div {
      grid-template-columns: auto;
    }
  }

  // Img special left / img special right in content
  // TODO improvement: could it work generally in content outside
  // frontpage in some way, via mixins etc.?

  .img-special-left,
  .img-special-right {
    > div {
      display: grid;
      @media (@screen-medium) {
        grid-template-columns: 1fr 1fr;
      }
      .vrtx-frontpage-box-picture {
        display: grid; // Control img height (or Safari stretches to viewport)
      }
      > div:has(.vrtx-media-player) {
        align-self: center;
        iframe {
          margin: 0;
        }
      }
      img {
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1; // Acts as minimum when height set to 100%
        object-fit: cover;
        margin: 0;
      }
      > div:not(:has(.vrtx-media-player)) {
        align-self: center;
        padding: 10%;
        @media (@screen-below-medium) {
          padding: 30px 15px;
        }
      }
    }
  }

  .img-special-right {
    > div {
      > div:has(.vrtx-media-player),
      img {
        @media (@screen-medium) {
          order: 2;
        }
      }
    }
  }

  .img-special-left,
  .img-special-right {
    h2::after {
      content: "";
      background-color: var(--color-black);
      display: block;
      height: 4px;
      width: 40px;
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }
    img {
      aspect-ratio: 4/3;
    }

    &.dark {
      background-color: var(--color-dark-blue);
      *,
      a {
        color: white;
      }
      .button-loud {
        color: var(--color-black);
        margin-right: var(--space-s);
      }
      .button {
        border-color: white;
        &::after {
          background-image: var(--arrow-medium-white);
        }
      }
      .read-more, .vrtx-more a, .vrtx-read-more, .all-messages, &.link-special a {
        &::after {
          background-image: var(--arrow-read-more-white);
          width: 60px;
          height: 60px;
        }
      }
      h2::after {
        background-color: var(--color-yellow);
      }
    }
  }
}



