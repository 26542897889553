// Research
// Everything inside *-listing affects only project-listing and group-listing
// #vrtx-project-listing, #vrtx-research-group-listing {
    //nothing here yet
// }

// Everything inside vrtx-structured-project affects only project and project group
body#vrtx-structured-project {
    #vrtx-content {
        max-width: var(--width-total);
        margin: 0 auto;
    }
}