@screen-large: ~"min-width: 1101px";
@screen-below-large: ~"max-width: 1100px";

#vrtx-frontpage {
    .grid-container.media-wide, #vrtx-content:has(h1.hidden):has(#vrtx-frontpage-introduction) .grid-container.media-wide {
        margin-top: -50px;
    }
    #vrtx-content:not(:has(#vrtx-frontpage-introduction)):not(:has(.feature)) {
        .grid-container {
            margin-top: 50px;
        }
        .grid-container:first-child.media-wide {
            margin-top: 0;
        }  
    }
    #vrtx-content:has(.media-wide) {
        h1 {
            margin-bottom: 0;
        }
    }


    .grid-container.row-all-colored {
        background-color: var(--color-secondary-green-light);
        .vrtx-frontpage-box {
            &.img-special-left, &.img-special-right, &.box-special, &.link-special {
                background-color: white;
            }
        }
    }

    .vrtx-frontpage-box.img-special-left img, .vrtx-frontpage-box.img-special-right img {
        aspect-ratio: 4/3;
    }

    .row-all-colored .vrtx-frontpage-box, .vrtx-frontpage-box{
        &.img-special-left, &.img-special-right, &.box-special {
            h2::after {
                content: "";
                background-color: var(--color-black);
                display: block;
                height: 4px;
                width: 40px;
                margin-top: var(--space-xxs);
                margin-bottom: var(--space-xxs);
            }
        }
    }

    // If box has vrtx-more remove link decoration
    .vrtx-frontpage-box:has(.vrtx-more) {
        h2 a, h3 a {
            text-decoration: none;
        }
    }
    .vrtx-frontpage-box {
        &.img-special-left, &.img-special-right, &.box-special, &.link-special {
            background-color: var(--color-secondary-green-light);
            border: none;
        }
    }

    .vrtx-frontpage-box.link-special a {
        text-decoration: underline;
        text-decoration-color: var(--color-yellow);
        text-decoration-thickness: 6px;
        &::after {
            background-image: var(--arrow-read-more);
            background-color: transparent;
            background-size: cover;
            width: 50px;
            height: 50px;
            margin-top: 20px;
        }
        &:hover {
            text-decoration-color: inherit;
        }
    }

    .grid-container {
        &.row-all-colored {
            margin-bottom: 100px;
        }
        &#footer-wrapper {
            margin-bottom: 0;
        }
    }

    .grid-container.row-all-colored {
        background-color: var(--color-secondary-green-light);
        .vrtx-frontpage-box {
            &.img-special-left, &.img-special-right, &.box-special, &.link-special {
                background-color: white;
            }
        }
        &:last-child {
            margin-bottom: -100px;
        }
    }

    .grid-container, .grid-container.row-all-colored {
        &:has(.dark), &:has(.feature) {
            .vrtx-frontpage-box {
                background-color: var(--color-dark-blue);
                *,
                a {
                    color: white;
                }
                .button-loud {
                    color: var(--color-black);
                    margin-right: var(--space-s);
                }
                .button {
                    border-color: white;
                    &::after {
                        background-image: var(--arrow-medium-white);
                    }
                }
                .read-more, .vrtx-more a, .vrtx-read-more, .all-messages, &.link-special a {
                    &::after {
                        background-image: var(--arrow-read-more-white);
                    }
                }
                &.img-special-left,
                &.img-special-right,
                &.box-special {
                    h2::after {
                        background-color: var(--color-yellow);
                    }
                }
            }
            &:has(.feature) {
                background-color: var(--color-dark-blue);
                padding-bottom: var(--space-l);
                margin-bottom: var(--space-l);
                .vrtx-box-content {
                    padding: 0 var(--space-l) 0 0;
                    order: 2;
                    align-self: end;
                    p {
                        margin-bottom: 0;
                    }
                }
                h2 {
                    font-size: 8rem;
                    font-size: clamp(3.6rem, 5.5vw, 8rem);
                    line-height: 110%;
                    &::after {
                        display: none;
                    }
                }
                .button-loud, .button {
                    margin-top: var(--space-s);
                }
                .vrtx-frontpage-box.img-special {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    max-height: 750px;
                    min-height: 450px;
                    padding-top: var(--space-s);
                    @media (@screen-below-large) {
                        grid-template-columns: 1fr;
                    }
                    .vrtx-frontpage-box-picture {
                        justify-self: self-end;
                        position: relative;
                        grid-row: 1 / 3;
                        grid-column: 2;
                        @media (@screen-below-large) {
                            grid-row-start: 3;
                            margin-top: var(--space-m);
                            grid-column: 1;
                        }
                        img {
                            max-width: 450px;
                            margin-bottom: 0;
                            @media (@screen-below-large) {
                                width: 75%;
                                margin-left: 25%;
                            }
                        }
                        &::before {
                            content: "";
                            background-image: var(--decor-feature);
                            background-repeat: no-repeat;
                            bottom: 0;
                            left: -98px;
                            height: 321px;
                            width: 261px;
                            background-size: cover;
                            position: absolute;
                            display: block;
                            @media (@screen-below-large) {
                                height: 55%;
                                width: 40%;
                                margin-left: 25%;
                            }
                        }
                    }
                }
                .vrtx-frontpage-box.img-special-left {
                    grid-template-columns: auto 1fr;
                    .vrtx-frontpage-box-picture {
                        grid-column-start: 1;
                        grid-row-start: 1;
                    }
                }
                .vrtx-frontpage-box.img-special-right {
                    grid-template-columns: 1fr auto;
                    padding-top: var(--space-m);
                    .vrtx-frontpage-box-picture {
                        @media (@screen-large) {
                            grid-column-start: 2;
                            grid-row: 1/3;
                        }
                    }
                    @media (max-width: 1020px) {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }
    }
    &.the-frontpage .grid-container:has(.feature) {
        margin-top: 0;
    }
}