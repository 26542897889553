// Everything inside vrtx-uh-person affects only person
#vrtx-uh-person {
    #vrtx-person-top-area {
        padding-bottom: var(--space-m);
        h1 {
            margin-bottom: 0;
            @media (@screen-medium) {
                grid-column: 2 / 3;
            }
        }
        .vrtx-position-unit {
            font-size: 2.1rem;
        }
        img {
            @media (@screen-medium) {
                grid-row: 1 / 5;
            }

            @media (@screen-below-medium) {
                grid-row: 1;
            }
        }
        .vrtx-person-contact-info dl.vrtx-person-contact-info-list {
            display: flex;
            column-gap: var(--space-s);
            dt {
                font-weight: 600;
                &::after {
                    content:":";
                }
            }
        }
    }
    #vrtx-content {
        max-width: var(--width-total);
        margin-left: auto;
        margin-right: auto;
    }
    .vrtx-article-body {
        border-bottom: none;
        padding-bottom: 30px;
    }
    .vrtx-groups.vrtx-frontpage-box {
        margin-bottom: 30px;
        padding-bottom: var(--space-xs);
    }
}

#vrtx-uh-person-listing {
    ul.vrtx-person-listing {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: var(--space-l) var(--space-m);
        list-style: none;
        margin-bottom: var(--space-l);
        padding: 0;
        li[class^='vrtx-person-'] {
            display: flex;
            flex-direction: column;
        }
        .vrtx-person-listing-name a:not(:has(img))::before {
            content: "";
            background: var(--color-black-light);
            display: block;
            width: 100%;
            aspect-ratio: 3 / 4;
            margin-bottom: var(--space-xs);
        }
        img {
            aspect-ratio: 3 / 4;
            margin-bottom: var(--space-xs);
            object-fit: cover;
        }
        .vrtx-person-listing-name-text {
            font-weight: 600;
        }
        .vrtx-person-listing-email {
            display: none;
        }
    }
}
