// PHS: typography.less
@main-font: 'Roboto Flex', sans-serif;

body {
    font-family: @main-font;
    font-weight: 400;
}
h1, h2, h3, h4, h5, h6, .vrtx-toc-header{
  font-weight: 600;
  strong {
    font-weight: 600;
  }
}
h1 {
  font-size: 4.5rem;
  padding-bottom: var(--space-s);
  margin-bottom: var(--space-s);
}

.vrtx-introduction {
  font-size: 2.6rem
}

@media (@screen-below-medium) {
  h1 {
    font-size: 3.6rem;
  }
  
  .vrtx-introduction {
    font-size: 2.1rem
  }
}

p, ul, ol, dl {
  margin: 0 0 var(--space-s) 0;
}

.button, .button-loud{
  font-size: 1.8rem;
  font-weight: 700;
}

a {
  color: var(--color-black);
  text-underline-offset: 0.1em;
  text-decoration-thickness: 0.07em;
}
#left-menu-same-level-folders ul .vrtx-marked {
  color: var(--color-black);
}
#header-tools #header-language {
  font-weight: 600;
  > a, > span {
    font-size: 1.6rem;
  }
}
#header-tools #header-language > a {
  color: white;
}
#breadcrumbs a {
  text-decoration: none;
}
#header-tools .toggle-search, #header-tools .sidebar-menu-toggle {
  font-size: 1.6rem;
  font-weight: 600;
}
#footers {
  a {
    color: white;
  }
  a.vrtx-login-manage-edit, a.vrtx-login-manage-admin {
    color: black;
  }
}

/*
Variable font. Same file for all. No dedicated italic.
*/

@font-face {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  //src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-EmF8tqHbE.woff2) format('woff2');
  src: url("../fonts/roboto/roboto-flex.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/roboto/roboto-flex.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/roboto/roboto-flex.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/roboto/roboto-flex.woff2") format("woff2");
}
@font-face {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/roboto/roboto-flex.woff2") format("woff2");
}
