:root {
// Viewport
--width-total-max: 1440px;
--width-total: 1040px;
--width-text-max: 690px;

// Spacing
--space-xxs: 5px;
--space-xs: 10px;
--space-xs-s: 15px;
--space-s: 20px;
--space-m: 40px;
--space-l: 80px;
--space-xl: 160px;
--space-xxl: 360px;

// Border-radius
--radius-s: 5px;
--radius-m: 15px;
--radius-l: 100px;

// Colours
// Main colours
--color-black: #2D2926;
--color-dark-blue: #00263E;
--color-yellow: #FDDA25; 
--color-light-blue: #ABCAE9;
--color-blue: #003865;
// Secondary colours
--color-secondart-red: #CF4520;
--color-secondary-yellow: #CC8A00;
--color-secondary-green: #4A7729;
--color-interactive-blue: #0072CE;
// Neutral colours
--color-black-light:#E2E5E7;
--color-dark-blue-light:#DDE4EA;
--color-box-bg: #FAF4E8;
--color-light-blue-light: #E3EEF8;
--color-blue-light: #DDE6EE;
--color-border: #919191;
// Neutral light colours
--color-secondart-red-light: #F9E8E6;
--color-secondary-yellow-light: #FAF4E8;
--color-secondary-green-light: #EDF1E9;
--color-interactive-blue-light: #E5F1FA;
--color-footer-gray: #D9D9D9;
// Attention colours
--color-crisis: #FF7578;
--color-info: #83B6FF;
--color-message: #FFD465;

//logo
--logo: url("../images/logo/logo-phs.svg");
--logo-inv: url("../images/logo/logo-phs-inv.svg");
--logo-en: url("../images/logo/logo-en.svg");

// Icons
--menu-inv: url("../images/menu-white.svg");
--search-inv: url("../images/search-white.svg");
--arrow: url("../images/arrow-medium.svg");
--arrow-medium: url("../images/arrow-medium.svg");
--arrow-medium-white: url("../images/arrow-medium-white.svg");
--arrow-small: url("../images/arrow-small.svg");
--arrow-short: url("../images/arrow-short.svg");
--arrow-read-more: url("../images/arrow-read-more.svg");
--arrow-read-more-white: url("../images/arrow-read-more-white.svg");
--icon-quote: url("../images/quote.svg");
--decor-feature: url("../images/decor-feature.svg"); // used for feature top box on front page
--icon-info-message: url("../images/crisis-banner-info.svg");
--icon-message: url("../images/message-icon.svg");
--arrow-footer-dropdown: url("../images/arrow-triangle-white.svg");

--img-alert: url("../images/warning-triangle.svg"); // crisis banner
}